import React from 'react';


const ThirdSection = () => {
  return (
    <div id="Services" className="bg-gradient-to-b from-gray-600 via-gray-600 via-30% to-gray-300 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">NIYOU</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-5xl">WE PARTNER WITH YOU</p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div className="flex flex-col items-center">
              <dt className="flex text-center items-center gap-x-3 text-2xl font-semibold leading-7 text-white">
                Build
                <img className="img-fluid" style={{ maxBlockSize: '100px', maxWidth: '100px' }} src="./images/logos/Asset 2.png" alt="Build Logo" />
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center">
                  We work with athletes and businesses to BUILD partnerships that are mutually beneficial and create connections that will last beyond the sport.
                </p>
                <p className="mt-6"></p>
              </dd>
            </div>
            <div className="flex flex-col items-center">
              <dt className="flex items-center gap-x-3 text-2xl font-semibold leading-7 text-white">
                Your
                <img className="img-fluid" style={{ maxBlockSize: '100px', maxWidth: '100px' }} src="./images/logos/Asset 4.png" alt="Your Logo" />
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center ">
                  To serve your interests, we have to understand you. We listen because, at the end of the day, it’s YOUR business.
                </p>
                <p className="mt-6"></p>
              </dd>
            </div>
            <div className="flex flex-col items-center">
              <dt className="flex items-center gap-x-3 text-2xl font-semibold leading-7 text-white">
                Brand
                <img className="img-fluid" style={{ maxBlockSize: '100px', maxWidth: '100px' }} src="./images/logos/ColorLogo.png" alt="Brand Logo" />
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center">
                  Branding is tough. We get that. Our creative team is ready to help with the creation and execution of content that effectively represents your BRAND.
                </p>
                <p className="mt-6"></p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
