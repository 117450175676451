import React from 'react';
import './App.css'; // This imports the main stylesheet (you should include Tailwind CSS here)
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FirstSection from './components/FirstSection';
import SecondSection from './components/SecondSection';
import ThirdSection from './components/ThirdSection';
import Athletes from './components/FourthSection';
import Partners from './components/Logos';
import About from './components/About';
import TeamSection from './components/Team';
import ContactSection from './components/Contact';
import Footer from './components/Footer';
import ClientModal from './components/Modal/ClientModal';
import { useState } from 'react';
import Shop from './components/shop';
import FullScreenImage from './components/workInProgress';
import AllAthletes from './components/allAthletes';
import DealRecap from './components/DealRecap';
import CaroselImage from './components/CaroselImage';
import MainComponent from './components/main';

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Router>
    <div className="App">
      <Routes>
        <Route exact path="/hidden" element={<FullScreenImage />} />
        <Route
          path="/"
          element={
            <MainComponent/>
          }
        />
        <Route path="/athletes" element={<><AllAthletes /><ContactSection></ContactSection> <Footer></Footer></>} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
