import React from 'react';

const ContactSection = () => {
  return (
    <div id="Contact" className="relative isolate bg-gradient-to-b from-white via-gray-700 via-10% to-gray-800 py-24 px-6 sm:py-32 lg:px-8">
      
      <div className="mx-auto max-w-xl lg:max-w-4xl">
        <h2 className="text-4xl font-bold tracking-tight text-white">Let’s talk about your next NIL partnership.</h2>
        <p className="mt-2 text-lg leading-8 text-gray-300">We help companies and athletes get the most out of NIL.</p>
        <p className="mt-2 text-lg leading-8 text-gray-300">Contact <b className="text-white">sam@ni-you.com</b> for more information.</p>
      </div>
    </div>
  );
};

export default ContactSection;
