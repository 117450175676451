import React, { useState, useEffect } from 'react';
import FullScreenImage from './workInProgress';
import FirstSection from './FirstSection';
import DealRecap from './DealRecap';
import AthleteCarousel from './CaroselImage';
import Partners from './Logos';
import About from './About';
import TeamSection from './Team';
import Shop from './shop';
import ContactSection from './Contact';
import Footer from './Footer';

const MainComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showDealRecap, setShowDealRecap] = useState(true);

  const handleVideoEnd = () => {
    setShowDealRecap(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative min-h-screen">
      <div
        className={`transition-opacity duration-500 ease-in-out absolute inset-0 ${
          showDealRecap ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        {showDealRecap && <FullScreenImage VideoEnd={handleVideoEnd} />}
      </div>
      <div
        className={`transition-opacity duration-500 ease-in-out ${
          showDealRecap ? 'opacity-0 pointer-events-none' : 'opacity-100'
        }`}
      >
        <FirstSection setModalOpen={setModalOpen} />
        <DealRecap />
        <AthleteCarousel />
        <Partners />
        <About />
        <ContactSection />
        <Footer />
      </div>
    </div>
  );
};

export default MainComponent;
