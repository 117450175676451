import React from 'react';
import { BASE_URL } from '../cdn';

const Partners = () => {
  return (
    <div id="Partners" className="bg-gray-600 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-semibold leading-8 text-white">Industry Partners</h2>
        <div className="mt-10 flex justify-center items-center gap-4 lg:gap-8">
          <img className="h-auto w-1/5 max-w-xs" src={`${BASE_URL}/images/logos/Companies/Canes.png`} alt="Canes" />
          <img className="h-auto w-1/5 max-w-xs" src={`${BASE_URL}/images/logos/Companies/Nissan.png`} alt="Nissan" />
          <img className="h-auto w-1/5 max-w-xs" src={`${BASE_URL}/images/logos/Companies/OmahaSteaks.png`} alt="Omaha Steaks" />
          <img className="h-auto w-1/6 max-w-xs" src={`${BASE_URL}/images/logos/Companies/Ozone.png`} alt="Ozone" />
          <img className="h-auto w-1/5 max-w-xs" src={`${BASE_URL}/images/logos/Companies/Nekter.png`} alt="Nekter" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
