import React, { useState, useEffect } from 'react';
import { athletes } from './allAthletes';

const AthleteCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 3000); // 3 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % athletes.length);
  };

  const getVisibleAthletes = (count) => {
    const visibleAthletes = [];
    for (let i = 0; i < count; i++) {
      visibleAthletes.push(athletes[(currentIndex + i) % athletes.length]);
    }
    return visibleAthletes;
  };

  const visibleAthletesDesktop = getVisibleAthletes(4);
  const visibleAthletesMobile = getVisibleAthletes(1);

  return (
    <div id="Athletes" className="pb-16 pt-24 sm:pb-24 sm:pt-32 xl:pb-32 relative bg-gradient-to-r from-blue-400 to-red-400 min-h-screen">
      <div className="pb-20 sm:pb-24 xl:pb-0">
      <h1 className="text-6xl md:text-8xl pb-10 text-black font-bold text-center">Athletes</h1>
        <div className="mx-auto max-w-screen-2xl px-6 lg:px-8">
          <div className="relative flex items-center overflow-hidden">
            {/* Desktop view: Show 4 athletes */}
            <div className="hidden md:flex w-full space-x-8 transition-transform duration-1000 ease-in-out">
              {visibleAthletesDesktop.map((athlete, index) => (
                <div key={index} className="w-[23%] flex-none">
                  <div className="relative h-[500px]">
                    <img
                      alt={athlete.name}
                      src={athlete.image}
                      className="absolute inset-0 h-full w-full rounded-2xl object-cover shadow-2xl"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-75 p-4 rounded-b-2xl">
                      <div className="text-black font-semibold">{athlete.name}</div>
                      <div className="text-gray-700">{athlete.info}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Mobile view: Show 1 athlete */}
            <div className="flex md:hidden w-full space-x-8 transition-transform duration-1000 ease-in-out">
              {visibleAthletesMobile.map((athlete, index) => (
                <div key={index} className="w-full flex-none">
                  <div className="relative h-[500px]">
                    <img
                      alt={athlete.name}
                      src={athlete.image}
                      className="absolute inset-0 h-full w-full rounded-2xl object-cover shadow-2xl"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-75 p-4 rounded-b-2xl">
                      <div className="text-black font-semibold">{athlete.name}</div>
                      <div className="text-gray-700">{athlete.info}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-8 flex justify-center space-x-2">
            {athletes.map((_, idx) => (
              <button
                key={idx}
                onClick={() => setCurrentIndex(idx)}
                className={`h-2.5 w-2.5 rounded-full ${idx === currentIndex ? 'bg-blue-600' : 'bg-gray-300'}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-20 bg-gradient-to-b from-transparent via-30% to-gray-600"></div>
    </div>
  );
};

export default AthleteCarousel;
