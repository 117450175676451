import { TabGroup, TabPanel, TabPanels } from '@headlessui/react'
import { BASE_URL } from '../cdn'

const product = {
  name: 'NIYOU Core T-Shirt',
  price: '$29.99',
  images: [
    {
      id: 1,
      name: 'Angled view',
      src:  `${BASE_URL}/images/store/AthleteMerch.jpg`,
      alt: 'Athlete wearing NIYOU shirt.',
    },
  ],
  colors: [
    { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
    { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
  ],
  description: `The Core, a premium t-shirt meant to represent the essence of who you are as an individual. This is the founding public merchandise of NIYOU. Only available August 2024.`,
}

export default function Shop() {
  return (
    <div id="Shop" className="bg-gradient-to-b from-gray-600 via-gray-500 via-10% to-white py-24 min-h-screen py-8">
      <main className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="text-5xl font-bold text-gray-900 mb-8 text-center">NIYOU Shop</h1>
          <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-8">
            <TabGroup className="flex flex-col-reverse">
              <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                <TabPanels className="aspect-w-1 aspect-h-1 w-full">
                  {product.images.map((image) => (
                    <TabPanel key={image.id}>
                      <img
                        alt={image.alt}
                        src={image.src}
                        className="h-full w-full object-cover object-center sm:rounded-lg"
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </div>
            </TabGroup>
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0 lg:pl-8 text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900">{product.name}</h1>
              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
                <p className="text-4xl tracking-tight text-gray-900">{product.price}</p>
              </div>
              <div className="mt-6">
                <h3 className="sr-only">Description</h3>
                <div className="space-y-6 text-lg text-gray-700">
                    <p>{product.description}</p>
                </div>
              </div>
              <form className="mt-6">
                <div className="mt-10 flex justify-center">
                  <button
                    type="button"
                    className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-gray-600 px-8 py-3 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                    onClick={() => window.open('https://buy.stripe.com/eVa16ieD31po88g3ce', '_blank')}
                  >
                    Pre-Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
