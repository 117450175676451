import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { BASE_URL } from '../cdn';

const TeamSection = () => {
  return (
    <div className="bg-gradient-to-b from-white via-gray-500 via-10% to-gray-600 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex justify-center items-center">
          <div className="mx-auto max-w-2xl text-center lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our team</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.</p>
          </div>
        </div>
        <ul role="list" className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4">
          {teamMembers.map(member => (
            <li key={member.name} className="flex flex-col items-center">
              <div className="overflow-hidden rounded-2xl w-full max-h-64">
              <img className="w-full h-full object-cover" src={member.image} alt={member.name} />
              </div>
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">{member.name}</h3>
              <p className="text-base leading-7 text-gray-300">
                {member.title}
              </p>
              <p className="text-sm leading-6 text-gray-500">{member.location}</p>
              <div className="flex space-x-2 mt-2">
                <a className="bg-gray-800 text-white p-2 rounded-full flex justify-center items-center h-10 w-10" href={`mailto:${member.email}`}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <a className="bg-gray-800 text-white p-2 rounded-full flex justify-center items-center h-10 w-10" href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamSection;

const teamMembers = [
  {
    name: 'Valery Shafack',
    title: 'Chief Executive Officer',
    location: 'Omaha, NE',
    email: 'val@ni-you.com',
    linkedin: 'https://www.linkedin.com/in/valery-shafack/',
    image: `${BASE_URL}/images/Staff/val.jpeg`
  },
  {
    name: 'Samuel Perkinson',
    title: 'Chief Relationship Officer',
    location: 'Omaha, NE',
    email: 'sam@ni-you.com',
    linkedin: 'https://www.linkedin.com/in/samuel-perkinson-38b343261/',
    image: `${BASE_URL}/images/Staff/sam.jpg`
  },
  {
    name: 'Landen Fogle',
    title: 'Chief Information Officer',
    location: 'Lincoln, NE',
    email: 'landen@ni-you.com',
    linkedin: 'https://www.linkedin.com/in/landen-fogle/',
    image: `${BASE_URL}/images/Staff/Landen1.jpg`
  },
  {
    name: 'Kylie Karsky',
    title: 'Chief Operating Officer',
    location: 'Omaha, NE',
    email: 'kylie@ni-you.com',
    linkedin: 'https://www.linkedin.com/in/kylie-karsky/',
    image: `${BASE_URL}/images/Staff/Kylie.jpg`
  },
 
];
