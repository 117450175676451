import React, { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../cdn';

const deals = [
    {
    title: 'Nissan of Omaha',
    athletes: ['Josiah Dotzler'],
    logo: `${BASE_URL}/images/logos/Companies/Nissan.png`,
    productVideoMobile: `${BASE_URL}/videos/Nissan.mp4`,
    productVideoDesktop: `${BASE_URL}/videos/Nissan.mp4`,
  },
  {
    title: 'Sonnys',
    athletes: ['Morgan Debow', 'Kali Jurgensmeier'],
    productVideoMobile: `${BASE_URL}/videos/SonnysVertical.mp4`,
    productVideoDesktop: `${BASE_URL}/videos/sonnys.mp4`,
  },
  {
    title: 'OmahaSteaks',
    athletes: ['Lauren Jensen'],
    productVideoMobile: `${BASE_URL}/videos/OmahaSteaksVertical.mp4`,
    productVideoDesktop: `${BASE_URL}/videos/Omahasteaks.mp4`,
  },
  {
    title: 'Homefield',
    athletes: [' Creighton Women’s Basketball'],
    productVideoMobile: `${BASE_URL}/videos/HomefieldVertical.mp4`,
    productVideoDesktop: `${BASE_URL}/videos/homefield.mp4`,
  },
  {
    title: 'Ozone',
    athletes: ['',],
    logo: `${BASE_URL}/images/logos/Companies/Ozone.png`,
    productVideoMobile: `${BASE_URL}/videos/OzoneRoofing.mp4`,
    productVideoDesktop: `${BASE_URL}/videos/OzoneRoofing.mp4`,
  }
];

const DealRecap = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleVideoEnd = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % deals.length);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + deals.length) % deals.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % deals.length);
  };

  const currentDeal = deals[currentIndex];

  return (
    <div id="Deals" className="relative w-full h-screen overflow-hidden">
      <video 
        autoPlay 
        muted 
        playsInline 
        className="absolute w-full h-full object-cover md:hidden"
        src={currentDeal.productVideoMobile}
        type="video/mp4"
        onEnded={handleVideoEnd}
      >
        Your browser does not support the video tag.
      </video>
      <video 
        autoPlay 
        muted 
        playsInline 
        className="hidden md:block absolute w-full h-full object-cover"
        src={currentDeal.productVideoDesktop}
        type="video/mp4"
        onEnded={handleVideoEnd}
      >
        Your browser does not support the video tag.
      </video>
      <div className="absolute bottom-0 left-0 w-full h-1/3"></div>
      <div className="absolute bottom-5 left-5 text-white z-10">
        <a href={currentDeal.url} className="text-3xl md:text-5xl">{currentDeal.title}</a>
        <div className="hidden md:flex items-center mt-2">
          {currentDeal.logo && <img src={currentDeal.logo} alt="Company Logo" className="h-10 mr-2" />}
          <p className="text-lg">{currentDeal.athletes.join(', ')}</p>
        </div>
      </div>
      <div className="absolute bottom-5 right-5 text-white z-10">
        <span className="text-lg md:text-xl">{currentIndex + 1} / {deals.length}</span>
      </div>
      <button
        onClick={handlePrevClick}
        className="absolute left-0 top-0 bottom-0 w-16 flex items-center justify-center text-white z-10 bg-gradient-to-r from-gray-800 to-transparent hover:from-gray-600"
      >
        &#x276E;
      </button>
      <button
        onClick={handleNextClick}
        className="absolute right-0 top-0 bottom-0 w-16 flex items-center justify-center text-white z-10 bg-gradient-to-l from-gray-800 to-transparent hover:from-gray-600"
      >
        &#x276F;
      </button>
    </div>
  );
};

export default DealRecap;