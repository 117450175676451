import React, { useState } from 'react';
import { BASE_URL } from '../cdn';

const AllAthletes = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src="./images/Logos/SmallLogoTransparent.png" alt="Your Company" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button id="menu-toggle" type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/" className="text-sm font-semibold leading-6 text-gray-900">Return to home</a>
          </div>
        </nav>
        {isMobileMenuOpen && (
          <div id="mobile-menu" className="lg:hidden fixed inset-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" role="dialog" aria-modal="true">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">NIYOU</span>
                <img className="h-8 w-auto" src="./images/Logos/SmallLogoTransparent.png" alt="NIYOU" />
              </a>
              <button id="menu-close" type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a href="/index" className="text-sm font-semibold leading-6 text-gray-900">Return to home</a>
                </div>
                <div className="py-6">
                  <a className="text-sm font-semibold leading-6 text-gray-900" id="login" href="/login">Log in <span aria-hidden="true">&rarr;</span></a>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <main>
        <div className="relative isolate">
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                  Meet the
                  <img className="inline-block h-25 w-25 mx-3 hidden sm:inline" src="./images/Logos/SmallLogoTransparent.png" alt="Your Company" /> 
                   &nbsp;Athletes
                </h2>
                <img className="block sm:hidden mx-auto h-25 w-25 my-4" src="./images/Logos/SmallLogoTransparent.png" alt="Your Company" />
              </div>
              <ul role="list" className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
                {athletes.map((athlete, index) => (
                  <li key={index} className="flex flex-col gap-6 xl:flex-row items-center sm:items-start">
                    <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover mb-4 sm:mb-0 sm:mr-4" src={athlete.image} alt={athlete.name} />
                    <div className="flex-auto text-center sm:text-left">
                      <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{athlete.name}</h3>
                      <p className="text-base leading-7 text-gray-600">{athlete.info}</p>
                      <p className="mt-6 text-base leading-7 text-gray-600">{athlete.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllAthletes;

export const athletes = [
  {
    name: 'Josiah Dotzler',
    info: 'Creighton Freshman - Basketball',
    description: 'Josiah Dotzler is a freshman from Omaha, Nebraska, representing Creighton basketball. With the help of NIL, he wants to use his influence to give back to his community and other communities like it. A unique fact about him is that he is naturally right-handed but plays basketball left-handed.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/JosiahDotzler.jpg`
  },
  {
    name: 'Lexi Unruh',
    info: 'Creighton Junior - Basketball',
    description: 'Lexi Unruh is a junior from Sioux Falls, SD, and a member of the women\'s basketball team. With NIL, she would love to build connections in the community. One of her favorite activities is traveling with her family.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/LexiUnruh2.jpg`
  },
  {
    name: 'Mallory Brake',
    info: 'Creighton Senior - Basketball',
    description: 'Mallory Brake is a member of the Women\'s Basketball (WBB) team at Creighton University and will be earning her degree in Marketing this May. She also has a new puppy named Georgie!',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/MalloryBrake.jpg`
  },
  {
    name: 'Brittany Harshaw',
    info: 'Creighton Sophomore - Basketball',
    description: 'Brittany Harshaw is redshirt freshman from Andover, Kansas on the Creighton women’s basketball team. She wants to make connections in the community of Omaha and help others promote their brand. Brittany was a tri-sport athlete in high school playing golf, basketball, and track.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/BrittanyHarshaw2.jpg`
  },
  {
    name: 'Jayme Horan',
    info: 'Creighton Senior - Basketball',
    description: 'Jayme Horan is a senior from Omaha, Nebraska, and a member of the women\'s basketball team. She is excited about the opportunities NIL provides to contribute to her hometown community. One of her passions is coaching her AAU team during the summer.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/BrittanyHarshaw2.jpg`
  },
  {
    name: 'Lauren Jensen',
    info: 'Creighton Senior - Basketball',
    description: 'Lauren Jensen is a senior on the Creighton women’s basketball team. She is from Lakeville, Minnesota and is looking to use her platform to do good within the community. She’s also looking to interact with businesses that align with her brand values.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/LaurenJensen1.jpg`
  },
  {
    name: 'Kiani Lockett',
    info: 'Creighton Sophomore - Basketball',
    description: 'Kiani Lockett is a sophomore from Minneapolis, Minnesota and she is a part of the Creighton women’s basketball team. What she wants to do with NIL is grow her brand to a larger audience and explore the endless opportunities available. A fun fact about Kiani is that she loves to travel and has been out of the country 5 times!',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/KianiLockett1.jpg`
  },
  {
    name: 'Morgan Maly',
    info: 'Creighton Senior - Basketball',
    description: 'Morgan Maly is a senior on Creighton\'s women\'s basketball team, from Crete, NE. She is interested in partnering with brands that share similar passions or values that she identifies with.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/MorganMaly.jpg`
  },
  {
    name: 'Molly Mogensen',
    info: 'Creighton Senior - Basketball',
    description: 'Molly Mogensen is a senior playing on Creighton’s women’s basketball team. She reigns from Farmington,Minnesota and a fun fact about her is that she has 3 siblings.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/MollyMogensen3.jpg`
  },
  {
    name: 'Emma Ronsiek',
    info: 'Creighton Senior - Basketball',
    description: 'Emma Ronsiek is from Sioux Falls, South Dakota, and a senior on the women\'s basketball team at Creighton. She finds NIL a great way to meet others and enhance her visibility in the world, hoping to continue making connections as her career progresses.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/EmmaRonsiek1.jpg`
  },
  {
    name: 'Kennedy Townsend',
    info: 'Creighton Sophomore - Basketball',
    description: 'Kennedy Townsend is a sophomore from Kansas City, Missouri on the Creighton women’s basketball team. NIL is something she wants to use to reach younger girls who want to be her shoes someday!',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/KennedyTownsend1.jpg`
  },
  {
    name: 'McKayla Miller',
    info: 'Creighton Freshman - Basketball',
    description: 'McKayla Miller is a freshman from St. John, KS, and a member of the women\'s basketball team. She aims to use NIL to grow her brand and make connections within the community.',
    image: `${BASE_URL}/images/Athletes/FirstPhotoShoot/Individuals/McKaylaMiller1.jpg`
  },
];
