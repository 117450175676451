import React, { useState } from 'react';
import { BASE_URL } from '../cdn';

const FullScreenImage = ({ VideoEnd }) => {
  const [videoEnded, setVideoEnded] = useState(false);

  const handleVideoEnd = () => {
    setVideoEnded(true);
    VideoEnd();
  };

  return (
    <div className="w-screen h-screen overflow-hidden flex justify-center items-center relative">
      {!videoEnded && (
        <>
          <video
            autoPlay
            muted
            playsInline
            className="absolute w-full h-full object-cover block lg:hidden"
            src={`${BASE_URL}/IntroVertical2.mp4`}
            type="video/mp4"
            onEnded={handleVideoEnd}
          >
            Your browser does not support the video tag.
          </video>
          <video
            autoPlay
            muted
            playsInline
            className="absolute w-full h-full object-cover hidden lg:block"
            src={`${BASE_URL}/Intro.mp4`}
            type="video/mp4"
            onEnded={handleVideoEnd}
          >
            Your browser does not support the video tag.
          </video>
        </>
      )}
    </div>
  );
};

export default FullScreenImage;
